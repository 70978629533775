import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import layoutStyles from './layout.module.css'
import './layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'My fav blog' },
          ]}
        >
          <html lang="en" />
        </Helmet>

        <div className={layoutStyles.container}>
          <Header siteTitle={data.site.siteMetadata.title} />
          <div className={layoutStyles.content}>

            {children}
          </div>
          <Footer />
        </div>
      </>
    )}
  />
)



export default Layout
